
import { Typography, makeStyles, Avatar } from "@material-ui/core";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
// import VisibilityIcon from '@mui/icons-material/Visibility';
import { useNavigate } from "react-router-dom";
import StatusEstabelecimento from "../../../../utils/statusEstabelecimento";
import ListaVazia from "../../../../utils/listaVazia";
import DateHora from "../../../../utils/dateHora";
import TempoPassado from "../../../../utils/tempoPassado";
import StatusEntrada from "../../../../utils/statusEntrada";


const useStyles = makeStyles((theme) => ({
    hover: { '&:hover': { backgroundColor: '#85287d8a' }, cursor: 'pointer' }
}))

export default function ListaInspecoes({ inspeccoes }) {
    const classes = useStyles()
    const navigate = useNavigate()

    return (
        <TableContainer >
            <Table id="myTable" size="small" stickyHeader aria-label="sticky table">
                <TableHead >
                    <TableRow>
                        <TableCell ><Typography noWrap> <strong>Inspector</strong></Typography></TableCell>
                        <TableCell ><Typography noWrap> <strong>Imagem</strong></Typography></TableCell>
                        <TableCell ><Typography noWrap> <strong>Nome</strong></Typography></TableCell>
                        <TableCell ><Typography noWrap> <strong>Tipo</strong></Typography></TableCell>
                        <TableCell ><Typography noWrap> <strong>Nº de Entrada</strong></Typography></TableCell>
                        {/* <TableCell ><Typography noWrap> <strong>Número de Processo</strong></Typography></TableCell> */}
                        <TableCell ><Typography noWrap> <strong>Director Técnico</strong></Typography></TableCell>
                        <TableCell ><Typography noWrap> <strong>Provincia</strong></Typography></TableCell>
                        <TableCell ><Typography noWrap> <strong>Solicitação</strong></Typography></TableCell>
                        <TableCell ><Typography noWrap> <strong>Estado</strong></Typography></TableCell>
                        <TableCell ><Typography noWrap> <strong>Data de inscrição</strong></Typography></TableCell>
                        <TableCell ><Typography noWrap> <strong>Ultima intervenção</strong></Typography></TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                    {inspeccoes.map((inspeccao) => (
                        <TableRow key={inspeccao?._id} className={classes.hover} onClick={() => navigate(`/admin/analise/solicitacao-de-licenciamento/${inspeccao.estabelecimento?._id}`)} title={inspeccao.estabelecimento?.nome}>

                            <TableCell component="th" scope="row">
                                {/* <Avatar src={inspeccao.inspectores[0]?.dadosPessoais.fotoURL} alt={inspeccao.inspectores[0]?.dadosPessoais.nome}/> */}
                                {inspeccao.inspectores[0]?.dadosPessoais.nome}
                            </TableCell>

                            <TableCell component="th" scope="row">
                                <Avatar variant="square" src={inspeccao.estabelecimento?.logo} alt={inspeccao.estabelecimento?.nome} />
                            </TableCell>

                            <TableCell >{inspeccao.estabelecimento?.nome}</TableCell>
                            <TableCell >{inspeccao.estabelecimento?.tipo?.designacao}</TableCell>
                            <TableCell >{inspeccao.estabelecimento?.numeroEntrada}</TableCell>
                            {/* <TableCell >{inspeccao.estabelecimento?.numeroProcesso}</TableCell> */}
                            <TableCell >{inspeccao.estabelecimento?.directorTecnico?.dadosPessoais?.nome}</TableCell>
                            <TableCell >{inspeccao.estabelecimento?.provincia}</TableCell>
                            <TableCell ><StatusEntrada status={inspeccao.estabelecimento?.tipoEntrada} />  </TableCell>
                            <TableCell >  <StatusEstabelecimento status={inspeccao.estabelecimento?.status} /></TableCell>
                            <TableCell ><DateHora date={inspeccao.estabelecimento.createdAt} /> </TableCell>
                            <TableCell ><TempoPassado date={inspeccao.estabelecimento.updatedAt} /> </TableCell>
                            {/* <TableCell > <Typography noWrap> {inspeccao.estabelecimento?.email}</Typography></TableCell> */}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>

            {inspeccoes.length < 1 && <ListaVazia text={'DE MOMENTO NÃO HÁ SOLICITACÕES PARA O SEU PERFIL INTERVIR'} />}
        </TableContainer>
    )
}
