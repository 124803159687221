import React, { useState } from 'react'
import './form.css'
import './drawer.css'
import { TextField, MenuItem, Alert, Stack } from '@mui/material';
import api from '../../../../services/api';
import ButtonLeft from '../../../../utils/buttonLeft';

interface Artigo {
    _id: string;
    artigoTitulo: string;
    temaArtigo: string;
    tipoArtigo: string;
    editores: string;
    palavrasChaves: string;
    isbn: string;
    escritorio: string;
    anoPublicado: string;
    pais: string;
    visaoGeral: string;
    imagem: string;
    anexo: string;
    createdAt: string;
}

interface FormCreateArtigoProps {
    Artigo: Artigo;
    setEditar: React.Dispatch<React.SetStateAction<boolean>>;
    setArtigos: React.Dispatch<React.SetStateAction<Artigo | null>>;
}

const FormEditarArtigo: React.FC<FormCreateArtigoProps> = ({ Artigo, setEditar, setArtigos }) => {

    const [isLoading, setIsLoading] = useState(false);
    const [msgSucess, setMsgSucess] = useState('')
    const [erro, setErro] = useState('')
    const [artigo, setArtigo] = useState<Artigo>({
        _id: '',
        artigoTitulo: Artigo.artigoTitulo,
        temaArtigo: Artigo.temaArtigo,
        tipoArtigo: Artigo.tipoArtigo,
        editores: Artigo.editores,
        palavrasChaves: Artigo.palavrasChaves,
        isbn: Artigo.isbn,
        escritorio: Artigo.escritorio,
        anoPublicado: Artigo.anoPublicado,
        pais: Artigo.pais,
        visaoGeral: Artigo.visaoGeral,
        imagem: '',
        anexo: '',
        createdAt: '',
    });


    const handleArtigoChange = (event: any) => {
        setArtigo({ ...artigo, [event.target.name]: event.target.value });
    };

    const handleSubmitArtigo = () => {
        setIsLoading(true);
        const formData = new FormData();

        formData.append('artigoTitulo', artigo.artigoTitulo);
        formData.append('temaArtigo', artigo.temaArtigo);
        formData.append('tipoArtigo', artigo.tipoArtigo);
        formData.append('editores', artigo.editores);
        formData.append('palavrasChaves', artigo.palavrasChaves);
        formData.append('isbn', artigo.isbn);
        formData.append('escritorio', artigo.escritorio);
        formData.append('anoPublicado', artigo.anoPublicado);
        formData.append('pais', artigo.pais);
        formData.append('visaoGeral', artigo.visaoGeral);
        formData.append('userId', '65a7b7b425d0c524741bd519');

        api.put('/artigo-cientifico/edit', {
            'artigoCientificoId': Artigo._id,
            'artigoTitulo': artigo.artigoTitulo,
            'temaArtigo': artigo.temaArtigo,
            'tipoArtigo': artigo.tipoArtigo,
            'editores': artigo.editores,
            'palavrasChaves': artigo.palavrasChaves,
            'isbn': artigo.isbn,
            'escritorio': artigo.escritorio,
            'anoPublicado': artigo.anoPublicado,
            'pais': artigo.pais,
            'visaoGeral': artigo.visaoGeral,
            'userId': '65a7b7b425d0c524741bd519',
        })
            .then(res => {
                setIsLoading(false);
                setMsgSucess(res.data.msg);
                setArtigos(res.data.artigoCientifico)
                setEditar(false);
            })
            .catch((error) => {
                setIsLoading(false);
                setErro(error.response.data.message);
            });
    }

    return (
        <div className='form-home'>
            <h1>Criar Artigo</h1>

            <Stack sx={{ width: '95%' }} spacing={2} style={{ margin: '10px' }}>
                {msgSucess && (<Alert severity="success">{msgSucess}</Alert>)}
                {erro && (<Alert severity="error">{erro}</Alert>)}
            </Stack>

            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                <TextField id="artigoTitulo"
                    label="Titulo Artigo"
                    variant="outlined"
                    name='artigoTitulo'
                    onChange={handleArtigoChange}
                    value={artigo.artigoTitulo}
                    className='txt-form'
                    style={{ margin: '5px' }}
                />

                <TextField
                    id="temaArtigo"
                    label="Tema do Artigo"
                    variant="outlined"
                    name='temaArtigo'
                    onChange={handleArtigoChange}
                    value={artigo.temaArtigo}
                    className='txt-form'
                    style={{ margin: '5px' }}
                />

                <TextField
                    id="tipoArtigo"
                    select
                    label="Tipo de Artigo"
                    defaultValue="Tipo de Artigo"
                    name='tipoArtigo'
                    onChange={handleArtigoChange}
                    value={artigo.tipoArtigo}
                    className='txt-form'
                    style={{ margin: '5px' }}
                >
                    <MenuItem value='Artigo Ciêntifico'>
                        Artigo Ciêntifico
                    </MenuItem>
                    <MenuItem value='Tipo de Artigo 2'>
                        Tipo de Artigo 2
                    </MenuItem>
                    <MenuItem value='Tipo de Artigo 3'>
                        Tipo de Artigo 3
                    </MenuItem>
                </TextField>

                <TextField
                    id="editores"
                    label="Editores"
                    variant="outlined"
                    name='editores'
                    onChange={handleArtigoChange}
                    value={artigo.editores}
                    className='txt-form'
                    style={{ margin: '5px' }}
                />

                <TextField
                    id="palavrasChaves"
                    label="Palavras Chaves"
                    variant="outlined"
                    name='palavrasChaves'
                    onChange={handleArtigoChange}
                    value={artigo.palavrasChaves}
                    className='txt-form'
                    style={{ margin: '5px' }}
                />

                <TextField
                    id="isbn"
                    label="Nº Referência (ISBN)"
                    variant="outlined"
                    name='isbn'
                    onChange={handleArtigoChange}
                    value={artigo.isbn}
                    className='txt-form'
                    style={{ margin: '5px' }}
                />

                <TextField
                    id="escritorio"
                    label="Escritorio de Publicação"
                    variant="outlined"
                    name='escritorio'
                    onChange={handleArtigoChange}
                    value={artigo.escritorio}
                    className='txt-form'
                    style={{ margin: '5px' }}
                />

                <TextField
                    label="Data Publicação"
                    id='anoPublicado'
                    name='anoPublicado'
                    type='date'
                    onChange={handleArtigoChange}
                    value={artigo.anoPublicado}
                    className='txt-form'
                    style={{ margin: '5px' }}
                // slotProps={{
                //     input: {
                //         startAdornment: <InputAdornment position="start"></InputAdornment>,
                //     },
                // }}
                />

                <TextField
                    id="pais"
                    label='Pais'
                    variant="outlined"
                    name='pais'
                    onChange={handleArtigoChange}
                    value={artigo.pais}
                    className='txt-form'
                    style={{ margin: '5px' }}
                />

                <TextField
                    className='txt-form'
                    name='visaoGeral'
                    id="visaoGeral"
                    label="Visão Geral"
                    multiline
                    rows={7.3}
                    onChange={handleArtigoChange}
                    value={artigo.visaoGeral}
                    style={{ margin: '5px', }}
                />

            </div>

            <ButtonLeft funcao={handleSubmitArtigo} load={isLoading} title={'Actualizar'} disabled={!artigo} children={null} />

        </div>
    )
}

export default FormEditarArtigo
