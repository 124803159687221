
import { useEffect, useState } from "react";
// import { Grid, makeStyles } from "@material-ui/core";
// import NavLateral from "./navLateral";
import HeaderSession from "../../../../utils/headerSession";
import { Card, Container, Divider, TextField, Typography } from "@mui/material";
import api from "../../../../services/api";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "../../../../load/loading";
import MenuIcon from '@mui/icons-material/Menu';
import TextoFormatado from "../../../../utils/textoFormatado";
import MessageSuccess from "../../../../messages/messageSuccess";
import MessageError from "../../../../messages/messageError";
import LoadingBackdrop from "../../../../load/loadingBackdrop";
import FichaEstabelecimento from "../../../gestLicenciados/fichaEstabelecimento";
import FichaDirectorTecnico from "../../../gestDirectorTecnico/fichaDirectorTecnico";
import FichaEmpresa from "../../../gestEmpresas/fichaEmpresa";
import DocumentosAnexados from "../../../gestLicenciados/documentosAnexados";
import RegisterAccess from "../../../../utils/registerAccess";
import AlertEdit from "../../../../utils/alertEdit";
import ButtonLeft from "../../../../utils/buttonLeft";


export default function EditeProcesso({ element: component, ...rest }) {
    const { id } = useParams()
    const navigate = useNavigate()
    const [estabelecimento, setEstabelecimento] = useState('')
    const [observacoesFeitas, setobservacoesFeitas] = useState('')
    const [messageSuccess, setMessageSuccess] = useState('')
    const [messageError, setMessageError] = useState('')
    const [load, setLoad] = useState(false);

    // descricao para solicitar nova inspeccao
    const [descricao, setDescricao] = useState('');

    useEffect(() => {
        api.get(`/estabelecimento/${id}`)
            .then(response => {
                // console.log(response)
                // || response.data.estabelecimento.status === 'Análise'
                (response.data.estabelecimento.status === 'Reprovado') ?
                    setEstabelecimento(response.data.estabelecimento)
                    :
                    // setEstabelecimento(response.data.estabelecimento)
                    navigate('/')
            }).catch(err => console.log(err));

        api.get(`/observacaos?estabelecimentoId=${id}`)
            .then(response => {
                // console.log(response)
                setobservacoesFeitas(response.data.observacaos[0]);
            }).catch(err => console.log(err));
    }, [id, navigate]);

    const changeStatus = async () => {
        setLoad(true)
        setMessageSuccess('')
        setMessageError('')

        await api.patch('/estabelecimento/change-status', { estabelecimentoId: id, status: 'Análise' })
            .then(res => {
                // console.log(res)
                setLoad(false)
                setMessageSuccess('O processo foi submetido para análise com sucesso.')

                window.setTimeout(() => {
                    navigate(`/`)
                }, 3000);

            }).catch(err => {
                setLoad(false)
                setMessageError(err.response.data.message)
            })
    }

    const solicitacaoNovaInspecao = async () => {
        setLoad(true)
        setMessageSuccess('')
        setMessageError('')

        await api.patch('/estabelecimento/solicitacao-nova-inspeccao', { estabelecimentoId: id, descricao })
            .then(res => {
                // console.log(res)
                setLoad(false)
                setMessageSuccess('O solicitação foi submetida com sucesso.')

                window.setTimeout(() => {
                    navigate(`/`)
                }, 3000);

            }).catch(err => {
                setLoad(false)
                setMessageError(err.response.data.message)
            })
    }

    return (
        <Container style={{ minHeight: '100vh' }}>

            <RegisterAccess page={'editar processo'} />
            <HeaderSession title={'EDIÇÃO DE PROCESSO '} />

            {messageSuccess && <MessageSuccess message={messageSuccess} />}
            {messageError && <MessageError message={messageError} />}
            <LoadingBackdrop open={load} text={'A Salvar edição'} />

            {(estabelecimento && observacoesFeitas) ?
                <>
                    {/* CASO FOR REPROPROVADO NA INSPECCAO */}
                    {estabelecimento.statusInspeccao === "Reprovada" &&
                        <Card style={{ padding: 20, marginBottom: 20 }}>
                            <AlertEdit texto={'Este processo foi reprovado porque o estabelecimento mostoru deficiência no acto a inspecção.'} />

                            <Typography >
                                Faça as alterações necessárias conforme as orientações recebida no E-mail.
                            </Typography>
                            <b>Observações:</b>
                            <TextoFormatado texto={observacoesFeitas?.descricao} />
                            <Divider />

                            <br />
                            <Typography >
                                Se já foram feitas as intervenções orientadas no estabelecimento, descrevi-as abaixo para solicitar uma nova inspecção.
                            </Typography>
                            <br />

                            <Typography >
                                <b>OBS:</b>Solicitar uma nova inspecção sem ter cumprido as orientações recebida pode causar a eliminação deste processo
                                e ARMED não se responsabilizará pelos danos que podem ser causados.
                            </Typography>

                            <TextField
                                // autoFocus
                                margin="dense"
                                label="Descrever"
                                fullWidth
                                size="small"
                                multiline
                                minRows={3}
                                name="descricao"
                                variant="outlined"
                                onChange={(e) => setDescricao(e.target.value)}
                            />
                            <small>Até 1000 caracteres: {descricao.length}</small>
                            <ButtonLeft title={'Solicitar nova inspecção'} funcao={solicitacaoNovaInspecao} disabled={descricao.length < 20 || descricao.length > 999} />
                        </Card>
                    }




                    {/* EXIBIDO PARA TODAS AS REPROVACOES */}
                    <>
                        {estabelecimento.statusInspeccao !== "Reprovada" &&
                            <Card style={{ padding: 20, marginBottom: 20 }}>

                                <h1 style={{ fontSize: 25 }}>
                                    <strong>
                                        Bem vindo(a) a correção de informações do seu processo de  Solicitação de licenciamento do estabelecimento farmacêutico.
                                    </strong>
                                </h1>

                                <br />
                                <Typography >
                                    Faça as alterações necessários conforme as orientações recebida no E-mail.
                                </Typography>
                                <b>Observações:</b>
                                <TextoFormatado texto={observacoesFeitas?.descricao} />

                                <br />
                                <Typography >
                                    O processo está organizado de forma separada de acordo ao tipo de informação,
                                    para poder editar clique em <MenuIcon />  e seleciona edição.
                                </Typography>
                                <br />
                                <Typography >
                                    Quando terminar todas as edições, clique em <b> SALVAR EDIÇÃO </b>
                                    para o processo ser submetido novamente para análise.
                                </Typography>

                                <ButtonLeft title={'Encaminhar processo'} funcao={changeStatus} />
                            </Card>
                        }

                        {/* EXIBINDO OS DADOS DOS ESTABELECIMENTO */}
                        <FichaEstabelecimento header={true} estabelecimento={estabelecimento} />
                        <FichaDirectorTecnico header={true} directorTecnico={estabelecimento.directorTecnico} />
                        <FichaEmpresa header={true} empresa={estabelecimento.empresa} local={'analiseSolicitacao'} />
                        <DocumentosAnexados estabelecimento={estabelecimento} docs={estabelecimento.docs} />

                        {estabelecimento.statusInspeccao !== "Reprovada" && <ButtonLeft title={'Encaminhar processo'} funcao={changeStatus} />}
                    </>
                </>
                :
                <Loading text={'A verificar processo.. Aguarde!'} />
            }
        </Container>
    )
}