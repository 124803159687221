
import { Avatar, Card, Container, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import HeaderSession from '../utils/headerSession'
import RegisterAccess from '../utils/registerAccess'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import api from '../services/api'
import FerramentaNavegacaoExportacao from '../utils/ferramentaNavegacaoExportacao'
import StatusEstabelecimento from '../utils/statusEstabelecimento'
import Loading from '../load/loading'
import SearshEstabelecimento from '../utils/searchEstabelecimento';

export default function ListaPublicaEstabelecimentosLicenciados() {
    const [estabelecimentos, setEstabelecimentos] = useState(useSelector(state => state?.estabelecimentoLicenciados?.data) ?? '')
    const [configLista, setConfigLista] = useState(useSelector(state => state?.estabelecimentoLicenciados?.config) ?? '')
    const [page, setPage] = useState(1);
    const dispatch = useDispatch();
    // const feedRef = useRef(null);
    const [perPage, setPerPage] = useState(100)
    const [order, setOrder] = useState('DESC')
    const [loading, setLoading] = useState(false);
    const [tipo, setTipo] = useState('');
    // console.log(page)

    useEffect(() => {
        setEstabelecimentos('')
    }, [tipo]);

    useEffect(() => {
        setLoading(true)
        api.get(`/estabelecimentos?page=${page}&status=Activo&perPage=${perPage}&sortBy=createdAt:${order}&tipoId=${tipo}`)
            .then(response => {
                // console.log(response)
                // setEstabelecimentos(response.data.estabelecimentos);
                setConfigLista(response.data);
                setEstabelecimentos((prevItems) => [...prevItems, ...response.data.estabelecimentos]);

            }).catch(err => { }).
            finally(() => { setLoading(false) })

        dispatch({
            type: 'estabelecimentoLicenciados',
            payload: { estabelecimentoLicenciados: estabelecimentos, config: configLista },
        }); //dispatch para por os estabelecimentos no state da aplicacao e evitar recarregar desnecessariamente

    }, [page, perPage, order, tipo]);



    return (
        <Container style={{ minHeight: '70vh', }}>
            <RegisterAccess page={'Estabelecimentos Licenciados'} />
            <HeaderSession title={'ESTABELECIMENTOS LICENCIADOS'} />

            <Card style={{ padding: 20 }}>
                <SearshEstabelecimento setEstabelecimentos={setEstabelecimentos} setTipo={setTipo} placeholder={'Pesquisar'} />

                {estabelecimentos &&

                    <TableContainer >
                        <Table id="myTable" size="small" stickyHeader aria-label="sticky table">
                            <TableHead >
                                <TableRow>
                                    <TableCell ><Typography noWrap> <strong>Imagem</strong></Typography></TableCell>
                                    <TableCell ><Typography noWrap> <strong>Nome</strong></Typography></TableCell>
                                    <TableCell ><Typography noWrap> <strong>Tipo</strong></Typography></TableCell>
                                    <TableCell ><Typography noWrap> <strong>Nif</strong></Typography></TableCell>
                                    {/* <TableCell ><Typography noWrap> <strong>Número de Processo</strong></Typography></TableCell> */}
                                    <TableCell ><Typography noWrap> <strong>Director Técnico</strong></Typography></TableCell>
                                    <TableCell ><Typography noWrap> <strong>Provincia</strong></Typography></TableCell>
                                    <TableCell ><Typography noWrap> <strong>Estado</strong></Typography></TableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {estabelecimentos.map((estabelecimento) => (
                                    <TableRow key={estabelecimento._id} >

                                        <TableCell component="th" scope="row">
                                            <Avatar variant='square' src={estabelecimento?.logo} alt={estabelecimento?.nome} />
                                        </TableCell>

                                        <TableCell >{estabelecimento?.nome}</TableCell>
                                        <TableCell >{estabelecimento?.tipo?.designacao}</TableCell>
                                        <TableCell >{estabelecimento?.empresa?.nif ?? '###'}</TableCell>
                                        {/* <TableCell >{estabelecimento?.numeroProcesso}</TableCell> */}
                                        <TableCell >{estabelecimento?.directorTecnico?.dadosPessoais?.nome}</TableCell>
                                        <TableCell >{estabelecimento?.provincia}</TableCell>
                                        <TableCell >
                                            <StatusEstabelecimento status={estabelecimento?.status} />
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>

                        <FerramentaNavegacaoExportacao
                            configLista={configLista}
                            lista={estabelecimentos}
                            page={page}
                            setPage={setPage}
                            setPerPage={setPerPage}
                            setOrder={setOrder}
                        // exportarExcel={exportarExcel}
                        />
                    </TableContainer>
                }

                {loading && <Loading text={'Lista Actualizada de Estabelecimentos Farmacêuticos Autorizados.'} />}
            </Card>

        </Container>
    )
}
