
// import Carrousel from '../../components/corousel/carousel';
import Footer from '../../components/footer';
import Links from './links/links';
import NavBar from '../../components/navBar';
import CarousselNoticias from './noticias/carousselNoticias';
import Servicos from './services';
import Slider from './slider/slider';
import Calendar from '../../admin/gestAgenda/calendario/calendar';
import CarousselDestaques from './destaques/carousselDestaques';
import RegisterAccess from '../../utils/registerAccess';
import FormCreateReclamacao from '../../admin/gestReclamacoes/utente/formCreateReclamacao';
import HomeArtigos from './artigosCientifico/listArtigos/artigosCientifico';
// import DocsProcurados from './docsProcurados';
// import PesquisarEstabelecimento from '../../admin/template/pesquisarEstabelecimento';

function Home() {

    return (
        <main>
            <RegisterAccess page={'pagina inicial'} />

            <NavBar />

            <Slider />

            {/* Modal de formulario de reclamacoes */}
            <FormCreateReclamacao />

            <Servicos /> 

            <Calendar />

            <CarousselNoticias />


            <CarousselDestaques />

            <HomeArtigos />

            <Links />

            <Footer />
        </main>

    );
}

export default Home;
