
import './Loading.css'; // Arquivo de estilos CSS para o componente de loading

const Loading = ({ text, focus }) => {
    return (
        <div className="loading-container" style={{ marginTop: 50 }}>
            {focus &&
                <input autoFocus type="text" style={{ opacity: 0, height: 1 }} />
            }

            <div className="loaderr"></div>

            {text && <p>{text}</p>}
        </div>
    );
};

export default Loading;