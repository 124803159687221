import React from 'react'
import HeaderSession from '../../../utils/headerSession'
import FormCreateArtigo from './createArtigo/formCreateArtigo'
import ArtigosCientifico from './listArtigos/artigosCientifico'
import { Card, Container } from '@mui/material'

export default function GestArtigosCientificos() {
    return (
        <Container>
            <HeaderSession title={'GESTÃO DE ARTIGOS CIÊNTIFICOS'} />

            <Card sx={{ padding: 4 }}>
                <FormCreateArtigo />

            </Card>

            <Card sx={{ padding: 4, marginTop: 5 }}>
                <ArtigosCientifico />
            </Card>

        </Container>
    )
}
