import React, { useState, CSSProperties } from 'react';
import { CiImageOn } from "react-icons/ci";

interface ImageUploaderProps {
    onFileSelect: (file: File | null) => void;
    maxSizeInMB?: number;
    style: CSSProperties;
}

const ImageUpload: React.FC<ImageUploaderProps> = ({ onFileSelect, maxSizeInMB = 5, style}) => {
    const [dragActive, setDragActive] = useState(false);
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [previewUrl, setPreviewUrl] = useState<string | null>(null);
    const [error, setError] = useState<string | null>(null);

    const validateFile = (file: File): boolean => {
        if (file.type !== 'image/jpeg' && file.type !== 'image/png' && file.type !== 'image/jpg') {
            setError('Por favor, selecione apenas arquivos JPEG, JPG ou PNG.');
            return false;
        }

        const fileSizeInMB = file.size / (1024 * 1024);
        if (fileSizeInMB > maxSizeInMB) {
            setError(`O arquivo excede o tamanho máximo de ${maxSizeInMB}MB.`);
            return false;
        }

        setError(null);
        return true;
    };

    const handleFileSelection = (file: File) => {
        if (validateFile(file)) {
            setSelectedFile(file);
            setPreviewUrl(URL.createObjectURL(file));
            onFileSelect(file);
        } else {
            setSelectedFile(null);
            setPreviewUrl(null);
            onFileSelect(null);
        }
    };

    const handleDragOver = (e: React.DragEvent<HTMLFormElement>) => {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(true);
    };

    const handleDragLeave = (e: React.DragEvent<HTMLFormElement>) => {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);
    };

    const handleDrop = (e: React.DragEvent<HTMLFormElement>) => {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);
        if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
            const file = e.dataTransfer.files[0];
            if (validateFile(file)) {
                setSelectedFile(file);
                setPreviewUrl(URL.createObjectURL(file));
                onFileSelect(file);
            } else {
                onFileSelect(null);
            }
            e.dataTransfer.clearData();
        }
    };

    const handleFileSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            handleFileSelection(e.target.files[0]);
        }
    };

    return (
        <div className="txt-form-drag" style={{...style, border:''}}>
            <form
                className="file-upload-form"
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
                onDrop={handleDrop}
            >
                <input
                    id="file-upload-input"
                    type="file"
                    onChange={handleFileSelect}
                    className="file-upload-input"
                    accept=".jpg,.jpeg,.png"
                />
                <label
                    htmlFor="file-upload-input"
                    className={`file-upload-label ${dragActive ? 'drag-active' : ''}`}
                >
                    <div className="upload-icon-container" >

                        {!previewUrl ? (
                            <>
                                <CiImageOn className="upload-img" style={
                                    {width:'30%'}
                                }/>
                                <p className="upload-text txt-name">{selectedFile?.name}</p>
                                <p className="upload-text" style={{paddingBottom:'10px'}}>
                                    Arraste e solte a Capa do artigo aqui ou clique para carregar (máx. {maxSizeInMB}MB)
                                    {error && <p className="error-message">{error}</p>}
                                </p>

                            </>
                        ) : (
                            <img
                                src={previewUrl}
                                alt="Preview"
                                className="preview-image"
                            />
                        )}
                    </div>
                </label>
            </form>
        </div>
    )
}

export default ImageUpload
