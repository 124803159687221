import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import api from '../../../../services/api';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Alert, Slide } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { format, formatDistanceToNow } from 'date-fns'
import { pt } from 'date-fns/locale'
import ImageUpload from '../createArtigo/imageUpload';
import FileUpload from '../createArtigo/fileUpload';
import FormEditarArtigo from '../createArtigo/formEditarArtigo';
import MessageSuccess from '../../../../messages/messageSuccess';
import MessageError from '../../../../messages/messageError';
import Loading from '../../../../load/loading';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

interface Artigo {
    _id: string;
    artigoTitulo: string;
    imagem: string;
    temaArtigo: string;
    tipoArtigo: string;
    editores: string;
    palavrasChaves: string;
    isbn: string;
    escritorio: string;
    anoPublicado: string;
    pais: string;
    visaoGeral: string;
    anexo: string;
    createdAt: string;
}

const ArtigoDetalhe: React.FC = () => {
    const { id } = useParams();

    const userId = '65a7b7b425d0c524741bd519'; //65a7b7b425d0c524741bd519
    const [idFile, setIdFile] = useState('');

    const [arquivos, setAquivos] = useState({
        imagem: '',
        anexo: ''
    })

    const [isLoading, setIsLoading] = useState(false);
    const [artigos, setArtigos] = useState<Artigo | null>(null);
    const [erro, setErro] = useState('')
    const [msgSucess, setMsgSucess] = useState('')
    const [open, setOpen] = useState(false);
    const [openConfirm, setOpenConfirm] = useState(false);
    const [openAnexo, setOpenAnexo] = useState(false);
    const [editar, setEditar] = useState(false);
    const [getDocsRefresh, setGetDocsRefresh] = useState(false);

    useEffect(() => {
        const fetchArtigo = async () => {
            try {
                setIsLoading(true);
                const response = await api.get(`/artigo-cientifico/${id}`);
                setArtigos(response.data.artigoCientifico);
            } catch (error) {
                setErro('Erro ao carregar o artigo');
            } finally {
                setIsLoading(false);
            }
        };

        fetchArtigo();
    }, [id, getDocsRefresh]);

    const deleteArtigo = (id: string) => {
        api.delete('/artigo-cientifico/delete', {
            data: {
                id: id,
                userId: '65a7b7b425d0c524741bd519'
            }
        }).then(() => {
            setGetDocsRefresh(true);
        })
            .catch(() => {
                setErro('Ocorreu algum ao apagar');
            });
    }

    const handleChangeImage = () => {
        setIsLoading(true);
        const formData = new FormData();

        formData.append('imagem', arquivos.imagem);
        formData.append('noticiaId', id ?? "");
        formData.append('userId', '65a7b7b425d0c524741bd519');


        api.patch('/artigo-cientifico/change-imagem', formData)
            .then(res => {
                console.log(res.data)
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
                setErro(error.response.data.message);
            });
    }

    const handleChangeAnexo = () => {
        setIsLoading(true);
        const formData = new FormData();

        formData.append('anexo', arquivos.anexo);
        formData.append('artigoCientificoId', id ?? "");
        formData.append('userId', '65a7b7b425d0c524741bd519');


        api.patch('/artigo-cientifico/change-anexo', formData)
            .then(res => {
                setArtigos(res.data.artigoCientifico)
                setMsgSucess(res.data.msg)
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
                setErro(error.response.data.message);
            });
    }

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const handleClickAnexo = () => {
        setOpenAnexo(true);
    };

    const handleCloseAnexo = () => {
        setOpenAnexo(false);
    };

    const handleFileInput = (file: File | null, name: string) => {
        setAquivos((prevArquivos) => ({
            ...prevArquivos,
            [name]: file,
        }));
    };

    const formatDateTime = (date: string) => {
        const dateObj = new Date(date)

        const sevenDaysInMs = 7 * 24 * 60 * 60 * 1000

        const fullFormat = format(dateObj, "dd/MM/HHHH", {
            locale: pt,
        })

        let timeDistance = formatDistanceToNow(dateObj, {
            locale: pt,
            addSuffix: true,
        })

        if (Date.now() - dateObj.getTime() > sevenDaysInMs) {
            return fullFormat
        }

        return timeDistance.replace("aproximadamente ", "")
    }

    const handleClick = (id: string) => {
        setOpenConfirm(true);
        setIdFile(id);
    };

    const handleCloseConfirm = () => {
        setOpenConfirm(false);
    };


    if (!artigos) {
        return <Alert severity="info">Nenhum Artigo Encontrado</Alert>
    }

    return (
        <>
            <div style={{ display: editar ? 'none' : 'flex', minHeight: '78vh' }}>
                <div className="article-details">

                    {msgSucess && <MessageSuccess message={msgSucess} />}
                    {erro && <MessageError message={erro} />}
                    {isLoading && <Loading text={'A carregar artigo.'} focus={false} />}

                    <>
                        <p className='article-details-overview'>Overview | <span style={{ color: '#0c4c8c', fontWeight: "600" }}>{artigos.artigoTitulo}</span></p>

                        <h3>{artigos.artigoTitulo}</h3>
                        <div className="article-details-date">
                            <p style={{ borderRight: '1px solid', padding: '0 10px' }}>{formatDateTime(artigos.createdAt)}</p>
                            <p>{artigos.tipoArtigo}</p>
                        </div>

                        <div className="article-details-view">
                            <div className='img'>
                                <div className="artigos-article-image-detalhe" onClick={handleClickOpen} style={{ cursor: 'pointer' }}>
                                    <img src={artigos?.imagem} alt='imagem do artigo' />
                                </div>
                                <button className="baixar-button">
                                    <a href={artigos.anexo} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: '#fff' }}>Baixar</a>
                                </button>
                            </div>

                            <div className='article-details-allView'>
                                <h4>Visão geral</h4>
                                <p>{artigos.visaoGeral}</p>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column' }} className='article-details-allView-right'>
                                <h5>Editores</h5>
                                <p>{artigos.editores}</p>
                                <h5>Número de Páginas</h5>
                                <p></p>
                                <h5>Número de Referência</h5>
                                <p>{artigos.isbn}</p>
                                <h5>Copyright</h5>

                                {/* FUNCIONALIDADE APENAS PARA ADMIN */}
                                {/* <div style={{ display: userId ? '' : 'none' }}>
                                    <button className="baixar-button" onClick={handleClickAnexo}>Alterar Ficheiro</button>
                                    <button className="edit-button" onClick={() => setEditar(true)}>Editar Artigo</button>
                                    <button className="delete-button" onClick={() => handleClick(artigos._id)} style={{ cursor: isLoading ? 'wait' : 'pointer' }}>Apagar</button>
                                </div> */}

                            </div>
                            <Dialog
                                open={open}
                                onClose={handleClose}
                            >
                                <DialogTitle>Alterar Capa</DialogTitle>
                                <DialogContent>
                                    <DialogContentText>
                                        <ImageUpload onFileSelect={(file) => handleFileInput(file, 'imagem')}
                                            style={{ display: 'flex', width: '100%' }} />
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={handleClose}>Fechar</Button>
                                    <Button onClick={handleChangeImage}>Alterar</Button>
                                </DialogActions>
                            </Dialog>

                            <Dialog
                                open={openAnexo}
                                onClose={handleCloseAnexo}
                            >
                                <DialogTitle>Alterar Anexo</DialogTitle>
                                <DialogContent>
                                    <DialogContentText>
                                        <FileUpload onFileSelect={(file) => handleFileInput(file, 'anexo')}
                                            style={{ display: 'flex', width: '100%' }} arquivo='o artigo' />
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={handleCloseAnexo}>Fechar</Button>
                                    <Button onClick={handleChangeAnexo}>Alterar</Button>
                                </DialogActions>
                            </Dialog>

                            <Dialog
                                open={openConfirm}
                                TransitionComponent={Transition}
                                keepMounted
                                onClose={handleCloseConfirm}
                                aria-describedby="alert-dialog-slide-description"
                            >
                                <DialogTitle>{"Deletar Documento"}</DialogTitle>
                                <DialogContent>
                                    <DialogContentText id="alert-dialog-slide-description">
                                        Deseja apagar esse documento?
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={handleCloseConfirm}>Não</Button>
                                    <Button onClick={() => deleteArtigo(idFile)}>Sim</Button>
                                </DialogActions>
                            </Dialog>
                        </div>
                    </>
                </div>
            </div>

            <div style={{ display: editar ? 'flex' : 'none' }}>
                <FormEditarArtigo Artigo={artigos} setEditar={setEditar} setArtigos={setArtigos} />
            </div>

            <br />
        </>
    )
}

export default ArtigoDetalhe
