import React, { useState, useEffect } from 'react'
import './artigo.css'
import { TextField, MenuItem, Container, Box, Skeleton } from '@mui/material';
import CountrySelect, { CountryType } from '../country/selectCountry';
import { format, formatDistanceToNow } from 'date-fns'
import { pt } from 'date-fns/locale'
import { useNavigate } from 'react-router-dom';
import api from '../../../../services/api';


interface Artigo {
    _id: string;
    artigoTitulo: string;
    imagem: string;
    temaArtigo: string;
    tipoArtigo: string;
    editores: string;
    palavrasChaves: string;
    isbn: string;
    escritorio: string;
    anoPublicado: string;
    pais: string;
    visaoGeral: string;
    anexo: string;
    createdAt: string;
}


const ArtigosCientifico: React.FC = () => {
    const navigate = useNavigate();

    const [artigos, setArtigos] = useState<Artigo[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    // const [erro, setErro] = useState('');
    const [selectedCountry, setSelectedCountry] = useState<CountryType | null>(null);
    const countryLabel = selectedCountry?.label || ""
    const [filtro, setFiltro] = useState({
        palavrasChaves: '',
        tipoArtigo: '',
        temaArtigo: '',
        escritorio: '',
        ano: '',
    });

    const handleCountryChange = (_event: React.SyntheticEvent<Element, Event>, newValue: CountryType | null,) => {
        setSelectedCountry(newValue);
    };

    const handleChange = (event: any) => {
        setFiltro({ ...filtro, [event.target.name]: event.target.value });
    }


    useEffect(() => {
        setIsLoading(true);
        // setErro('');
        api.get(`/artigos-cientifico?palavrasChaves=${filtro.palavrasChaves}&tipoArtigo=${filtro.tipoArtigo}&temaArtigo=${filtro.temaArtigo}&escritorio=${filtro.escritorio}&anoPublicado=${filtro.ano}&pais=${countryLabel}`)
            .then(res => {
                setIsLoading(false);
                setArtigos(res.data.artigosCientifico);
            })
            .catch(() => {
                setIsLoading(false);
                // setErro("Ocorreu algum erro ao carregar os Artigos");
            });
    }, [filtro.palavrasChaves, filtro.tipoArtigo, filtro.temaArtigo, filtro.escritorio, filtro.ano, countryLabel])

    // const formatDateTime = (date: string) => {
    //     const dateObj = new Date(date)

    //     const sevenDaysInMs = 7 * 24 * 60 * 60 * 1000

    //     const fullFormat = format(dateObj, "dd/MM/HHHH", {
    //         locale: pt,
    //     })

    //     let timeDistance = formatDistanceToNow(dateObj, {
    //         locale: pt,
    //         addSuffix: true,
    //     })

    //     if (Date.now() - dateObj.getTime() > sevenDaysInMs) {
    //         return fullFormat
    //     }

    //     return timeDistance.replace("aproximadamente ", "")
    // }

    function SkeletonCard() {
        return (
            <Box>
                <Box sx={{ width: 210, marginRight: 0.5, my: 5 }} className="h-[350px]">
                    <Skeleton animation="wave" variant="rectangular" width={210} className="h-[200px]" />
                    <Box sx={{ pt: 0.5 }}>
                        <Skeleton animation="wave" />
                        <Skeleton animation="wave" width="60%" />
                    </Box>
                </Box>
            </Box>
        );
    }

    return (
        <Container>
            {/* <div className='home'> */}
            <br />
            <br />

            <h1>Artigos</h1>

            <p>Veja os artigos selecionados abaixo.</p>

            {/* <Stack sx={{ width: '95%' }} spacing={2} style={{ margin: '10px' }}>
                {erro && (<Alert severity="error">{erro}</Alert>)}
                {artigos.length === 0 && (
                    <Alert severity="info">Nenhum Artigo Encontrado</Alert>
                )}
            </Stack> */}

            <div className="filtro-section">
                <TextField
                    sx={{ margin: '2px', marginTop: '0' }}
                    name='palavrasChaves'
                    id="outlined-basic"
                    label="Pesquisa por Palavras-chaves"
                    variant="outlined"
                    className='txtTipo txt'
                    onChange={handleChange}
                />
                <TextField
                    disabled
                    sx={{ marginLeft: '2px', marginTop: '0' }}
                    id="outlined-select-currency"
                    select
                    label="Tipo"
                    className='txtTipo txt'
                    name='tipoArtigo'
                    onChange={handleChange}
                >
                    {artigos.map((artigo) => (
                        <MenuItem key={artigo._id} value={artigo.tipoArtigo} >
                            {artigo.tipoArtigo}
                        </MenuItem>
                    ))}
                </TextField>

                <div style={{ margin: '15px 0' }}>
                    <TextField
                        sx={{ margin: '2px', marginTop: '0' }}
                        id="outlined-select-currency"
                        select
                        label="Tema"
                        className='txtTema txt'
                        name='temaArtigo'
                        onChange={handleChange}
                    >
                        {artigos.map((artigo) => (
                            <MenuItem key={artigo._id} value={artigo.temaArtigo} >
                                {artigo.temaArtigo}
                            </MenuItem>
                        ))}
                    </TextField>

                    <TextField
                        disabled
                        sx={{ margin: '2px', marginTop: '0' }}
                        id="outlined-select-currency"
                        select
                        label="Escritório de Publicação"
                        className='txtTipo txt'
                        name='escritorio'
                        onChange={handleChange}
                    >
                        {artigos.map((artigo) => (
                            <MenuItem key={artigo._id} value={artigo.escritorio} >
                                {artigo.escritorio}
                            </MenuItem>
                        ))}
                    </TextField>

                </div>
                <div style={{ display: 'flex' }}>
                    <CountrySelect
                        name="country"
                        value={selectedCountry}
                        handleChange={handleCountryChange}
                    />

                    <TextField
                        sx={{ margin: '2px', marginTop: '0' }}
                        id="outlined-select-currency"
                        label="Ano"
                        className='anoTxt txt'
                        name='ano'
                        onChange={handleChange}
                        style={{ margin: '0 20px', marginRight: "10px" }}
                    />
                </div>
            </div>

            {isLoading ?
                <div className="artigos-articles-container" >
                    {
                        [...Array(5)].map((_, index) => (
                            <div className="artigos-article-card" style={{ cursor: 'progress' }} key={index}>
                                <SkeletonCard />
                            </div>
                        ))
                    }
                </div>
                // <div className="loader-artigo" style={{ display: 'flex', margin: 'auto', justifyContent: 'center', marginTop: '100px' }}></div>
                :
                <div className="artigos-articles-container">
                    {artigos.map((artigo, index) => (
                        <div className="artigos-article-card" key={index}>
                            <div className="artigos-article-image">
                                <img src={artigo.imagem} alt='imagem do artigo' />
                            </div>
                            <div className="artigos-article-info" >
                                {/* <p className="artigos-article-date" style={{ fontSize: '16px', padding: '0', marginTop: '20px' }}>{formatDateTime(artigo.createdAt)}</p> */}
                                <h3 className="artigos-article-title"> {artigo.artigoTitulo.length > 100 ? artigo.artigoTitulo.slice(0, 100) + '...' : artigo.artigoTitulo}</h3>
                                <div className="artigos-article-links">
                                    <a href={artigo.anexo} target="_blank" className="artigos-article-download">Baixar</a>
                                    <div className="artigos-article-read-more" onClick={() => navigate(`/view-artigo-cientifico/${artigo._id}`)}>Leia Mais</div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            }
            {/* </div> */}
        </Container>
    )
}

export default ArtigosCientifico
