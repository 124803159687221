import React, { useState, useEffect } from 'react'
import './form.css'
import './drawer.css'
import { TextField, MenuItem } from '@mui/material';
import FileUpload from './fileUpload';
import ImageUpload from './imageUpload';
import api from '../../../../services/api';
import { useNavigate, useParams } from 'react-router-dom';
import ButtonLeft from '../../../../utils/buttonLeft';
import MessageError from '../../../../messages/messageError';
import MessageSuccess from '../../../../messages/messageSuccess';

interface Artigo {
    artigoTitulo: string;
    temaArtigo: string;
    tipoArtigo: string;
    editores: string;
    palavrasChaves: string;
    isbn: string;
    escritorio: string;
    anoPublicado: string;
    pais: string;
    visaoGeral: string;
}

const FormCreateArtigo: React.FC = () => {

    const { id } = useParams();
    const navigate = useNavigate();

    const [arquivos, setAquivos] = useState({
        anexo: '',
        imagem: '',
    })

    const [isLoading, setIsLoading] = useState(false);
    const [msgSucess, setMsgSucess] = useState('')
    const [erro, setErro] = useState('')
    const [artigo, setArtigo] = useState<Artigo>({
        artigoTitulo: '',
        temaArtigo: '',
        tipoArtigo: '',
        editores: '',
        palavrasChaves: '',
        isbn: '',
        escritorio: '',
        anoPublicado: '',
        pais: '',
        visaoGeral: '',
    });


    const handleArtigoChange = (event: any) => {
        setArtigo({ ...artigo, [event.target.name]: event.target.value });
    };

    const handleFileInput = (file: File | null, name: string) => {
        setAquivos((prevArquivos) => ({
            ...prevArquivos,
            [name]: file,
        }));
    };

    const handleSubmitArtigo = () => {
        setIsLoading(true);
        const formData = new FormData();

        formData.append('artigoTitulo', artigo.artigoTitulo);
        formData.append('temaArtigo', artigo.temaArtigo);
        formData.append('tipoArtigo', artigo.tipoArtigo);
        formData.append('editores', artigo.editores);
        formData.append('palavrasChaves', artigo.palavrasChaves);
        formData.append('isbn', artigo.isbn);
        formData.append('escritorio', artigo.escritorio);
        formData.append('anoPublicado', artigo.anoPublicado);
        formData.append('pais', artigo.pais);
        formData.append('visaoGeral', artigo.visaoGeral);
        formData.append('anexo', arquivos.anexo);
        formData.append('imagem', arquivos.imagem);
        formData.append('userId', '65a7b7b425d0c524741bd519');

        if (id) {
            api.put('/artigo-cientifico/edit', {
                'artigoCientificoId': id,
                'artigoTitulo': artigo.artigoTitulo,
                'temaArtigo': artigo.temaArtigo,
                'tipoArtigo': artigo.tipoArtigo,
                'editores': artigo.editores,
                'palavrasChaves': artigo.palavrasChaves,
                'isbn': artigo.isbn,
                'escritorio': artigo.escritorio,
                'anoPublicado': artigo.anoPublicado,
                'pais': artigo.pais,
                'visaoGeral': artigo.visaoGeral,
                'userId': '65a7b7b425d0c524741bd519',
            })
                .then(res => {
                    setIsLoading(false);
                    setMsgSucess(res.data.msg);
                    navigate(`/ler/mais/${res.data.artigoCientifico._id}`)
                })
                .catch((error) => {
                    setIsLoading(false);
                    setErro(error.response.data.message);
                });
        } else {
            api.post('/artigo-cientifico/create', formData)
                .then(res => {
                    setIsLoading(false);
                    setMsgSucess(res.data.msg);
                    setArtigo({
                        artigoTitulo: '',
                        temaArtigo: '',
                        tipoArtigo: '',
                        editores: '',
                        palavrasChaves: '',
                        isbn: '',
                        escritorio: '',
                        anoPublicado: '',
                        pais: '',
                        visaoGeral: '',
                    })
                })
                .catch((error) => {
                    setIsLoading(false);
                    setErro(error.response.data.message);
                });
        }
    }

    useEffect(() => {
        if (id) {
            api.get(`/artigo-cientifico/${id}`)
                .then((response) => {
                    setArtigo(response.data.artigoCientifico);
                    setIsLoading(false);
                })
                .catch(() => {
                    setErro('Erro ao carregar o artigo');
                    setIsLoading(false);
                })
        }
    }, [id]);

    return (
        <div>
            <h1>Criar Artigo</h1>

            {msgSucess && <MessageSuccess message={msgSucess} />}
            {erro && <MessageError message={erro} />}

            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
               
                <TextField id="artigoTitulo"
                    label="Titulo Artigo"
                    variant="outlined"
                    name='artigoTitulo'
                    onChange={handleArtigoChange}
                    value={artigo.artigoTitulo}
                    className='txt-form'
                    style={{ margin: '5px' }}
                />

                <TextField
                    id="temaArtigo"
                    label="Tema do Artigo"
                    variant="outlined"
                    name='temaArtigo'
                    onChange={handleArtigoChange}
                    value={artigo.temaArtigo}
                    className='txt-form'
                    style={{ margin: '5px' }}
                />

                <TextField
                    id="tipoArtigo"
                    select
                    label="Tipo de Artigo"
                    defaultValue="Tipo de Artigo"
                    name='tipoArtigo'
                    onChange={handleArtigoChange}
                    value={artigo.tipoArtigo}
                    className='txt-form'
                    style={{ margin: '5px' }}
                >
                    <MenuItem value='Artigo Ciêntifico'>
                        Artigo Ciêntifico
                    </MenuItem>
                    <MenuItem value='Tipo de Artigo 2'>
                        Tipo de Artigo 2
                    </MenuItem>
                    <MenuItem value='Tipo de Artigo 3'>
                        Tipo de Artigo 3
                    </MenuItem>
                </TextField>

                <TextField
                    id="editores"
                    label="Editores"
                    variant="outlined"
                    name='editores'
                    onChange={handleArtigoChange}
                    value={artigo.editores}
                    className='txt-form'
                    style={{ margin: '5px' }}
                />

                <TextField
                    id="palavrasChaves"
                    label="Palavras Chaves"
                    variant="outlined"
                    name='palavrasChaves'
                    onChange={handleArtigoChange}
                    value={artigo.palavrasChaves}
                    className='txt-form'
                    style={{ margin: '5px' }}
                />

                <TextField
                    id="isbn"
                    label="Nº Referência (ISBN)"
                    variant="outlined"
                    name='isbn'
                    onChange={handleArtigoChange}
                    value={artigo.isbn}
                    className='txt-form'
                    style={{ margin: '5px' }}
                />

                <TextField
                    id="escritorio"
                    label="Escritorio de Publicação"
                    variant="outlined"
                    name='escritorio'
                    onChange={handleArtigoChange}
                    value={artigo.escritorio}
                    className='txt-form'
                    style={{ margin: '5px' }}
                />

                <TextField
                    label="Data Publicação"
                    id='anoPublicado'
                    name='anoPublicado'
                    type='date'
                    onChange={handleArtigoChange}
                    value={artigo.anoPublicado}
                    className='txt-form'
                    style={{ margin: '5px' }}
                // slotProps={{
                //     input: {
                //         startAdornment: <InputAdornment position="start"></InputAdornment>,
                //     },
                // }}
                />

                <TextField
                    id="pais"
                    label='Pais'
                    variant="outlined"
                    name='pais'
                    onChange={handleArtigoChange}
                    value={artigo.pais}
                    className='txt-form'
                    style={{ margin: '5px' }}
                />


                <FileUpload onFileSelect={(file) => handleFileInput(file, 'anexo')} arquivo="o Artigo" style={{
                    display: id ? 'none' : 'flex'
                }}
                />

                <ImageUpload onFileSelect={(file) => handleFileInput(file, 'imagem')}
                    style={{
                        display: id ? 'none' : 'flex'
                    }}
                />
                <TextField
                    className='txt-form'
                    name='visaoGeral'
                    id="visaoGeral"
                    label="Visão Geral"
                    multiline
                    rows={7.3}
                    onChange={handleArtigoChange}
                    value={artigo.visaoGeral}
                    style={{ margin: '5px', }}
                />

            </div>

            <ButtonLeft funcao={handleSubmitArtigo} load={isLoading} title={id ? 'Actualizar' : 'Criar'} disabled={!artigo} children={null} />

        </div>
    )
}

export default FormCreateArtigo
